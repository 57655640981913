import * as Sentry from '@sentry/browser';

const sentryConfig = {
  ...window.sentryClientConfig,
  integrations: [
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['dantoo-corporate-site-bundle'],
      behaviour: 'drop-error-if-exclusively-contains-third-party-frames'
    })
  ]
};

Sentry.init(sentryConfig);

